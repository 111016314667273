import { Injectable } from '@angular/core';
import { Action, State, type StateContext } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import { EMPTY, forkJoin, Observable } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import { optimisticUpdate, syncLoadProgress } from '@cosmos/state';
import type { ModelWithLoadingStatus } from '@cosmos/types-common';
import { ToastActions } from '@cosmos/types-notification-and-toast';
import type { LinkRelationship, LinkType } from '@cosmos/types-party';
import { CompaniesService } from '@esp/companies/data-access-api';
import { CompaniesLinksSearchCriteria } from '@esp/companies/types-data-access';
import { ContactsService } from '@esp/contacts/data-access-api';
import type { Contact } from '@esp/parties/types';
import type { SearchStateModel } from '@esp/search/types-search';

import { CompaniesLinksActions } from '../actions';

import { TOAST_MESSAGES } from './toast-messages';

export interface CompaniesLinksStateModel
  extends SearchStateModel<LinkRelationship[]>,
    ModelWithLoadingStatus {
  criteria: CompaniesLinksSearchCriteria;
  items: LinkRelationship[];
  itemsCount: number;
}

type LocalStateContext = StateContext<CompaniesLinksStateModel>;

@State<CompaniesLinksStateModel>({
  name: 'companyLinks',
  defaults: {
    criteria: new CompaniesLinksSearchCriteria(),
    items: [],
    itemsCount: 0,
  },
})
@Injectable()
export class CompaniesLinksState {
  constructor(
    private readonly _service: CompaniesService,
    private readonly _contactsService: ContactsService
  ) {}

  @Action(CompaniesLinksActions.SearchContactsLinks)
  private _getContactsLinks(
    ctx: LocalStateContext,
    { id, criteria }: CompaniesLinksActions.SearchContactsLinks
  ) {
    return this._searchLinksWithCount(id, 'contact', criteria).pipe(
      syncLoadProgress(ctx),
      tap((linksWithCount) => {
        ctx.patchState(linksWithCount);
      })
    );
  }

  @Action(CompaniesLinksActions.SearchWithExistingCriteria)
  private _searchWSearchWithExistingCriteria(ctx: LocalStateContext) {
    const { criteria } = ctx.getState();

    return this._searchLinksWithCount(
      criteria.id as number,
      'contact',
      criteria
    ).pipe(
      syncLoadProgress(ctx),
      tap((linksWithCount) => {
        ctx.patchState(linksWithCount);
      })
    );
  }

  @Action(CompaniesLinksActions.PrepareCriteria)
  private _prepareCriteria(
    ctx: LocalStateContext,
    { criteria }: CompaniesLinksActions.PrepareCriteria
  ) {
    ctx.patchState({
      items: [],
      itemsCount: 0,
      criteria,
    });
  }

  @Action(CompaniesLinksActions.CreateLink)
  private _createLink(
    ctx: LocalStateContext,
    { payload }: CompaniesLinksActions.CreateLink
  ) {
    const { items: currentLinks, itemsCount: currentTotal } = ctx.getState();
    const updatedLinks = [...ctx.getState().items, payload.link];
    const updatedCount = currentTotal + 1;

    return this._service.createLink(payload.link, payload.companyId).pipe(
      map((link) => [...currentLinks, link]),
      optimisticUpdate<LinkRelationship[]>(updatedLinks, {
        getValue: () => currentLinks,
        setValue: (items) => {
          ctx.setState(
            patch({
              items: items,
              itemsCount: updatedCount,
            })
          );
        },
      }),
      tap(() => {
        ctx.dispatch(
          new ToastActions.Show(
            TOAST_MESSAGES.CONTACT_LINK_CREATED(payload.link.To!.Name!)
          )
        );
      }),
      catchError(() => {
        ctx.dispatch(
          new ToastActions.Show(TOAST_MESSAGES.CONTACT_LINK_NOT_CREATED())
        );

        return EMPTY;
      })
    );
  }

  @Action(CompaniesLinksActions.RemoveLink)
  private _removeLink(
    ctx: LocalStateContext,
    { payload }: CompaniesLinksActions.RemoveLink
  ) {
    return this._service.removeLink(payload.companyId, payload.linkId).pipe(
      tap(() => {
        ctx.dispatch(
          new ToastActions.Show(TOAST_MESSAGES.CONTACT_LINK_REMOVED())
        );
      }),
      catchError(() => {
        ctx.dispatch(
          new ToastActions.Show(TOAST_MESSAGES.CONTACT_LINK_NOT_REMOVED())
        );

        return EMPTY;
      })
    );
  }

  @Action(CompaniesLinksActions.PatchLink)
  private _patchLink(
    ctx: LocalStateContext,
    { payload }: CompaniesLinksActions.PatchLink
  ) {
    return this._service
      .patchLink(payload.link, payload.companyId, payload.linkId)
      .pipe(
        tap((updatedLink) => {
          ctx.patchState({
            items: ctx
              .getState()
              .items.map((link) =>
                link.Id === updatedLink.Id ? updatedLink : link
              ),
          });
          ctx.dispatch(
            new ToastActions.Show(
              TOAST_MESSAGES.CONTACT_LINK_UPDATED(payload.link.To!.Name!)
            )
          );
        }),
        catchError(() => {
          ctx.dispatch(
            new ToastActions.Show(TOAST_MESSAGES.CONTACT_LINK_NOT_UPDATED())
          );

          return EMPTY;
        })
      );
  }

  @Action(CompaniesLinksActions.CreateContact)
  private _createNewContact(
    ctx: LocalStateContext,
    { contact }: CompaniesLinksActions.CreateContact
  ) {
    return this._contactsService.create(contact).pipe(
      tap(() => {
        ctx.dispatch(
          new ToastActions.Show(
            TOAST_MESSAGES.CONTACT_CREATED(
              `${contact.GivenName} ${contact.FamilyName}`
            )
          )
        );
      }),
      switchMap((resp) => this._reloadContactsLinks(ctx, resp)),
      catchError(() => {
        ctx.dispatch(
          new ToastActions.Show(TOAST_MESSAGES.CONTACT_NOT_CREATED())
        );

        return EMPTY;
      })
    );
  }

  private _reloadContactsLinks(
    ctx: LocalStateContext,
    newContact: Contact
  ): Observable<{ items: LinkRelationship[]; itemsCount: number }> {
    const { criteria } = ctx.getState();

    return this._searchLinksWithCount(
      criteria.id as number,
      'contact',
      criteria
    ).pipe(
      syncLoadProgress(ctx),
      tap(({ items, itemsCount }) => {
        const companyContactLink = newContact.Links.find(
          (link) => link?.To?.Id === (criteria.id as number)
        );

        ctx.patchState({
          items: [
            ...items.filter((link) => link.Id !== newContact.Links[0].Id),
            ...(companyContactLink
              ? [this._mapNewContactToLink(newContact, companyContactLink)]
              : []),
          ],
          itemsCount: itemsCount,
        });
      })
    );
  }

  private _mapNewContactToLink(
    newContact: Contact,
    companyContactLink: LinkRelationship
  ): LinkRelationship {
    return {
      Id: companyContactLink.Id,
      To: newContact,
      Title: companyContactLink.Title,
      IsEditable: companyContactLink.IsEditable,
    };
  }

  private _searchLinksWithCount(
    id: number,
    type: LinkType,
    criteria: Partial<CompaniesLinksSearchCriteria>
  ): Observable<{ items: LinkRelationship[]; itemsCount: number }> {
    return forkJoin([
      this._service.searchLinks(id, type, criteria),
      this._service.getLinksCount(id, type),
    ]).pipe(
      map(([links, linksCount]) => {
        return { items: links, itemsCount: linksCount };
      })
    );
  }
}
